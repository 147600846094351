<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit common area') + ` id: ${form.id}` : $t('Create common area reservation') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="building" label="Building" v-model="form.building_id" :options="property.building_list" @input="onValidateBuilding" />
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" :disabled="!form.building_id" name="common_area_id" label="Common area" v-model="form.common_area_id" :options="lists.common_areas" @input="onValidateCommonArea" :isValid="isValid('common_area_id')" :errors="getErrors('common_area_id')" />

              <Calendar v-if="showCalendar" ref="calendar" :config="config" :availability="availability[form.common_area_id]" :reservation="reservation" :reservations="reservations" :key="form.common_area_id + reservations.length" @registerEvent="onRegisterEvent" @removeEvent="onRemoveEvent" />
            </ACard>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import Calendar from '@/app/components/utils/Calendar'
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'CommonAreaReservationForm',
  mixins: [formMixin],
  components: {
    Calendar
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0 },

      // Extra
      lists: {},
      availability: {},

      // Helpers
      alerts: [],
      validators: {},
      reservation: {},
      current_crud: {},
      reservations: [],
      is_principal: false,
      building_data: [],
      reservation_data: [],
      original_lists: {},
      showCalendar: false,

      config: {
        reminders: true
      }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/common_area_reservations' + (self.form.id ? `/${self.form.id}/edit` : '/create'), { _lists: 'common_areas' })
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
        })
    },
    onModalUpdated(items) {
      this.lists[this.current_crud.type] = items
      // Force refresh list
      const value = this.form[this.current_crud.field]
      this.form[this.current_crud.field] = ''
      this.$nextTick(() => {
        this.form[this.current_crud.field] = value
      }, 100)
    },
    onValidateBuilding() {
      this.showCalendar = false
      this.config.building = {}
      this.$set(this.form, 'common_area_id', '')

      if (this.form.building_id) {
        const building_data = this.building_data.find(item => item.id === this.form.building_id)

        if (building_data) {
          const building = this.property.building_list.find(item => item.id === this.form.building_id) // TODO: use common variable
          building_data.identifier = building.label
        }

        // Config
        this.config.building = building_data || {}
      }
    },

    onValidateCommonArea() {
      this.reservations = []
      this.showCalendar = false

      if (this.form.common_area_id) {
        const availability = this.availability[this.form.common_area_id]

        // TODO: Validate current overdue_value
        if (availability.value && this.config.building.overdue_value > availability.value) {
          this.config.disabled = 'No puede reservar esta zona común, actualmente presenta deuda'
          this.showAlert(this.config.disabled, 'warning')
        }

        this.reservations = this.reservation_data.filter(item => item.common_area_id === this.form.common_area_id)
        this.showCalendar = true
        this.config.size = Object.keys(this.availability[this.form.common_area_id].availability[0].shifts).length
        this.config.common_area = this.lists.common_areas.find(item => item.id === this.form.common_area_id) // TODO: use common variable
      }
    },

    onRemoveEvent(args) {
      const self = this
      self.$http
        .delete('admin/common_area_reservations', { id: args.id })
        .then(response => {
          // TODO: remove from array or grab from DB
          self.parseExtraData(response.data)
          self.showAlert(`Common area reservation deleted.`)
        })
        .catch(error => {
          self.showAlert('There was an error.', 'danger')
          console.error(error)
        })
    },

    onRegisterEvent(args) {
      //console.log(args)
      const reminders = []
      for (const reminder of args.form.reminders) {
        if (reminder.date && reminder.recipients.length) reminders.push(reminder)
      }

      const form = {
        ...this.form,
        //id: 0, // TODO: validate
        id: args.form.id || 0,
        min_date: args.event.start,
        max_date: args.event.end,
        all_day: false, // TODO: Check
        min_time: args.event.start,
        max_time: args.event.end,
        concurrency: args.form.concurrency,
        details: args.event.title,
        reminders
      }

      //console.log(this.form)

      const self = this
      self.$http[form.id ? 'put' : 'post']('admin/common_area_reservations', form) // TODO: on service ?
        .then(response => {
          //self.$router.replace(`/admin/tools/common_area_reservations/${response.data.common_area_reservation.id}/edit`).catch(() => {})
          self.parseExtraData(response.data)
          self.showAlert(`Common area reservation ${self.form.id ? 'saved' : 'created'}.`)
          //self.parseData(response.data)

          // TODO: register event on calendar here!!!
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.showAlert(error.response.data.error, 'warning')

            // TODO: Validate errors or codes ?
          } else if (error.response?.data?.message == 'Not available.') {
            self.showAlert(`Not available.`, 'warning')
            console.error(error)
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the common area reservation.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      //this.form = data.common_area_reservation ? data.common_area_reservation : { id: 0 }
      this.is_principal = !!this.form.is_principal
      this.availability = data.availability

      this.parseExtraData(data)

      if (!Array.isArray(data._lists)) this.lists = data._lists || {}

      if (this.form.id) {
        this.reservation = data.common_area_reservation
        this.form.building_id = data.common_area_reservation.building_id
        this.onValidateBuilding()
        this.form.common_area_id = data.common_area_reservation.common_area_id
        this.onValidateCommonArea()

        if (this.$refs.calendar) this.$refs.calendar.onEditEvent(data.common_area_reservation)
      }

      this.parseView()

      /*this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)*/

      //if (this.form.id) this.forcedSteps()
    },

    parseExtraData(data) {
      this.building_data = data.building_data
      this.reservation_data = data.reservation_data
      this.onValidateCommonArea()
    },

    parseView() {
      const options = [
        //{ click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'CommonAreaReservation']: options })
    }
  }
}
</script>
